@import './../../../../scss/theme-bootstrap';

.waitlist-form {
  $input-row-max-width: 450px;
  padding: 0 10px;
  background: $color-pink-2;
  height: auto;
  text-align: center;
  max-width: 100vw;
  @include breakpoint($large-up) {
    margin: auto;
  }
  &__header {
    @include header-small;
    margin-bottom: 3px;
  }
  &__subheader {
    @include body-medium;
    margin-bottom: 10px;
  }
  &__message {
    @include body-small;
  }
  &__errors {
    @include body-small;
    color: $color-pink-accent;
    margin: 1em 0;
  }
  &__button-row {
    display: flex;
    flex-direction: column;
    max-width: $input-row-max-width;
    margin: 0 auto;
    @include breakpoint($large-up) {
      flex-direction: row;
    }
    &__email-field {
      flex: 1;
      text-align: center;
      @include breakpoint($large-up) {
        text-align: left;
      }
    }
  }
  &__button {
    @include swap_direction(margin, 0 -9px 0 -4px);
    min-width: 120px;
  }
  &__success__header {
    background: $color-black;
    color: $color-white;
    padding: 10px;
  }
  &__email {
    @include swap_direction(margin, 0 12px 0 -9px);
    min-width: calc(100% - 145px);
  }
  input[type='email'] {
    width: 100%;
    border: 0px;
    text-align: inherit;
    &:focus,
    &:active {
      & + label:before {
        color: $color-dark-gray;
      }
    }
    & + label {
      margin-bottom: 0;
      &:before {
        color: $color-dark-gray;
        @include breakpoint($large-up) {
        }
      }
    }
  }
  input[type='submit'] {
    @include button--black;
    min-width: auto;
    height: 40px;
    color: $color-pink-button-text;
    margin-top: 10px;
    @include breakpoint($large-up) {
      margin-top: 0;
    }
  }
  &__email-optin {
    max-width: $input-row-max-width;
    margin: 0 auto;
    margin-top: 15px;
  }
  &__signin-block__checkbox {
    display: flex;
    input[type='checkbox'] {
      margin-right: 5px;
    }
    .label {
      font-size: 13px;
      text-align: left;
      p {
        margin: 0;
      }
      a {
        color: $color-pink-accent;
      }
    }
  }
  &__gdpr {
    display: flex;
    max-width: $input-row-max-width;
    margin: 0 auto;
    margin-top: 10px;
    &__info-link {
      color: $color-pink-accent;
      font-weight: bold;
      font-size: 13px;
    }
  }
}

#colorbox.colorbox--waitlist {
  #cboxClose {
    padding-top: 20px;
    &:before {
      font-size: 35px;
    }
  }
}
